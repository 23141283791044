import React, { useContext, useEffect, useRef } from 'react';
import { Button } from '@material-tailwind/react';

import { NewContext } from '../../Context/NewContext';
import { toast } from 'react-toastify';

export const FileInput = ({ validation = true, id, accept, name, previewFields, imgClass, required = true, isChildModal = false, isImage, width = 128, height = 128, acceptedExtensions }) => {
    const { addEditDeleteTag, formData, setFormData, childFormData, setChildFormData, imgObj, setImgObj } = useContext(NewContext);

    const wrapperRef = useRef(null);
    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const fileRemove = (_ele, name) => {
        setFormData({ ...formData, [_ele]: undefined });
        setImgObj({ ...imgObj, [name]: undefined, [_ele]: undefined });
    }

    const onFileChange = (e) => {
        const fileObject = e.target.files[0];
        if (!fileObject || !fileObject.name.match(acceptedExtensions)) {
            return toast.error('Please select valid image.');
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(fileObject);

            reader.onload = function (_e) {

                const image = new Image();
                const imageContent = reader.result;
                const decodedString = atob(imageContent.split(',')[1]);
                image.src = imageContent.split(',')[0] + ',' + btoa(decodedString);
                image.onerror = function () {
                    return toast.error('Please select valid image.');
                };
                image.onload = function () {
                    const imgHeight = this.height
                    const imgWidth = this.width;
                    if (validation) {
                        if (imgWidth > width || imgHeight > height) {
                            return toast.error(`Width and Height size must be ${width}x${height}.`);
                        }
                    }

                    let updatedFile = fileObject;

                    // Apply renaming only for bigPreview or smallPreview
                    if (e.target.name === "bigPreviewTheme") {
                        const originalFileName = fileObject.name.replace(/\s+/g, '_'); // Replace spaces with underscores
                        const fileExtension = fileObject.name.substring(fileObject.name.lastIndexOf('.'));
                        const newFileName = `big_${Date.now()}_${originalFileName}`;

                        updatedFile = new File([fileObject], newFileName, {
                            type: fileObject.type,
                            lastModified: fileObject.lastModified,
                        });
                    } else if (e.target.name === "smallPreviewTheme") {
                        const originalFileName = fileObject.name.replace(/\s+/g, '_'); // Replace spaces with underscores
                        const fileExtension = fileObject.name.substring(fileObject.name.lastIndexOf('.'));
                        const newFileName = `small_${Date.now()}_${originalFileName}`;

                        updatedFile = new File([fileObject], newFileName, {
                            type: fileObject.type,
                            lastModified: fileObject.lastModified,
                        })
                    } else if (e.target.name === "backgroundImgDiy") {
                        const originalFileName = fileObject.name.replace(/\s+/g, '_'); // Replace spaces with underscores
                        const fileExtension = fileObject.name.substring(fileObject.name.lastIndexOf('.'));
                        const newFileName = `small_${originalFileName}`;

                        updatedFile = new File([fileObject], newFileName, {
                            type: fileObject.type,
                            lastModified: fileObject.lastModified,
                        })
                    } else if (e.target.name === "backgroundPreviewDiy") {
                        const originalFileName = fileObject.name.replace(/\s+/g, '_'); // Replace spaces with underscores
                        const fileExtension = fileObject.name.substring(fileObject.name.lastIndexOf('.'));
                        const newFileName = `big_${originalFileName}`;

                        updatedFile = new File([fileObject], newFileName, {
                            type: fileObject.type,
                            lastModified: fileObject.lastModified,
                        })
                    }

                    setFormData({ ...formData, [e.target.name]: updatedFile });
                    setImgObj({ ...imgObj, [e.target.name]: URL.createObjectURL(updatedFile) });
                };
            };
        }
    }
    const fileChildRemove = (_ele, name) => {
        setChildFormData({ ...childFormData, [_ele]: undefined });
        setImgObj({ ...imgObj, [name]: undefined, [_ele]: undefined });
    }
    const onChildFileChange = (e) => {
        const fileObject = e.target.files[0];
        if (fileObject && !fileObject.name.match(/\.(jpg|jpeg|png|gif|webp|PNG)$/)) {
            return toast.error('Please select valid image.');
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(fileObject);
            reader.onload = function (_e) {
                const image = new Image();
                const imageContent = reader.result;
                const decodedString = atob(imageContent.split(',')[1]);
                image.src = imageContent.split(',')[0] + ',' + btoa(decodedString);
                image.onerror = function () {
                    return toast.error('Please select valid image.');
                };
                image.onload = function () {
                    if (validation) {
                        const imgHeight = this.height
                        const imgWidth = this.width;
                        if (imgWidth > width || imgHeight > height) {
                            return toast.error(`Width and Height size must be ${width}x${height}.`);
                        } else {
                            setChildFormData({ ...childFormData, [e.target.name]: fileObject });
                            setImgObj({ ...imgObj, [e.target.name]: URL.createObjectURL(fileObject) });
                        }
                    } else {
                        setChildFormData({ ...childFormData, [e.target.name]: fileObject });
                        setImgObj({ ...imgObj, [e.target.name]: URL.createObjectURL(fileObject) });
                    }
                };
            };
        }
    }

    useEffect(() => {
        setImgObj(_prev => ({ ..._prev, [previewFields]: formData[previewFields] || childFormData[previewFields] }));
    }, [previewFields]);

    return (
        <>
            {
                imgObj[name] || imgObj[previewFields] !== undefined ?
                    <div className='file-preview rounded-[10px] h-auto relative border-2 border-dashed border-[#CCCCCC]'>
                        <img src={imgObj[name] ? imgObj[name] : `${imgObj[previewFields]}`} alt={formData?.name || childFormData?.name} className={`w-auto p-1 rounded-[10px] ${imgClass}`} />
                        {addEditDeleteTag !== 'view' && <Button variant='filled' className="file-remove-icon !absolute m-auto rounded-full top-2.5 right-2.5 leading-normal py-1.5 px-3 bg-[#F62929] shadow-none hover:shadow-none " onClick={() => { isChildModal ? fileChildRemove(previewFields, name) : fileRemove(previewFields, name) }}>Remove</Button>}
                    </div>
                    :
                    <div
                        ref={wrapperRef}
                        className={`drop-file-input h-[241px] rounded-[10px] relative flex items-center justify-center py-10 hover:opacity-[0.6] border-2 border-dashed border-[#CCCCCC] break-words p-2`}
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                    >
                        <p className='text-sm leading-none font-semibold'><span className='text-purple'>Click to upload photo</span> or drag and drop</p>
                        <input id={id} accept={accept} name={name} type='file' onChange={isChildModal ? onChildFileChange : onFileChange} className='absolute w-full h-full cursor-pointer opacity-0 top-0 left-0 ' required={required} />
                    </div>
            }
        </>
    );
}

export default FileInput;