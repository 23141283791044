import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, IconButton, Checkbox, Spinner, Tooltip } from "@material-tailwind/react";
import { BiEdit, } from 'react-icons/bi';
import { IoEyeOutline } from 'react-icons/io5';
import { HiDownload } from 'react-icons/hi';
import Zoom from 'react-medium-image-zoom';

import { Dropdowns, Pagination, SearchAndAddBtn, Switch, THead } from '../../../Components';
import { NewContext } from '../../../Context/NewContext';
import { get_tab, get_theme_categories_data, get_themes_data } from '../../../Controller/actions';
import ThemeModal from '../../../Components/Modals/ThemeModal';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { RiDeleteBinLine } from 'react-icons/ri';
import { MdOutlineCategory } from 'react-icons/md';

const FilterDropdowns = ({ dropData, tabData }) => {
    return (
        <div className='flex gap-2'>
            <Dropdowns title={`Categories`} name={"category"} Icon={MdOutlineCategory} dropData={dropData} />
            <Dropdowns title={`Tabs`} name={"tab"} Icon={MdOutlineCategory} dropData={tabData} />
            <Dropdowns title={`Total Downloads`} name={"download"} Icon={HiDownload} dropData={[]} download={true} />
        </div>
    )
}

export const ThemeStores = ({ title }) => {
    const { permissions, tokenType, capitalalizeFirstletter, setIsLoadMore, setDeleteUpdateTag, confirmationModalShow, setConfirmationModalShow, setFormData, setAddEditDeleteTag, setDeleteUpdateItem, isLoading, setDeleteUpdateYes, handleSwitchUpdate, checkedValues, handleCheckboxChange, filterDropdown, deleteUpdateTag, search, totalPage, currentPage, perPage, setTotalPage, setPerPage, setCurrentPage, setIsLoading, currentPageData, setCurrentPageData, handleOpen } = useContext(NewContext);
    const simpleFields = [
        { name: "Preview", },
        // { name: "Big Preview", },
        { name: "Name", sortable: true, field: 'name' },
        // { name: "Category", },
        { name: "Tag", },
        { name: "Country", },
        { name: "User Hits" },
        // { name: "Badge" },
    ];
    const [pagePermissions, setPagePermissions] = useState({
        CU: true,
        R: true,
        D: true
    });
    const dropdownFields = [{ name: "Premium" }, { name: "Top" }, { name: "LiveToday", }, { name: "Active", }];
    const totalFields = simpleFields.length + dropdownFields.length + 2;

    const [category, setCategory] = useState([]);
    const [tab, setTab] = useState([])
    const getCategoryData = async () => {
        const categoryData = await get_theme_categories_data(1, "", "", { isActive: true });
        categoryData?.data?.map(_ele => setCategory(_prev => !category.includes(_prev) && [..._prev, { id: _ele._id, label: capitalalizeFirstletter(_ele.name) }]))
    }
    const getTabData = async () => {
        const tabData = await get_tab(1, "", "", { isActive: true });
        tabData?.data?.map(_ele => setTab(_prev => !tab.includes(_prev) && [..._prev, { id: _ele._id, label: capitalalizeFirstletter(_ele.name) }]))
    }

    useEffect(() => {
        getCategoryData();
        getTabData()
    }, [])

    useEffect(() => {
        if (tokenType === 'ldap') {
            const update = permissions?.subPermissions?.find((route) => route.name === 'Themes Store');
            setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
        }
    }, [tokenType, permissions])

    const numberFormatter = (value) => {
        let newValue = value
        const suffixes = ['', 'K', 'M', 'B', 'T']
        let suffixNum = 0
        while (newValue >= 1000) {
            newValue /= 1000
            suffixNum++
        };
        newValue = Math.abs(value) > 999 ? Number(newValue?.toPrecision(3).split('.')[1]) > 90 ? Math.abs(newValue?.toPrecision(1)).toFixed(1) : Math.abs(newValue?.toPrecision(3)).toFixed(1) : Math.abs(newValue).toFixed(1)

        let newNumber = parseInt(newValue)
        newNumber += Math.abs(value) > 1000 ? `${suffixes[suffixNum]}+` : `${suffixes[suffixNum]}`
        return newNumber
    };


    useEffect(() => {
    }, [pagePermissions])

    const getThemesData = async (_currentPage = 1) => {
        setIsLoading(true);
        try {
            const getThemes = await get_themes_data(_currentPage, perPage, search, filterDropdown);
            if (getThemes) {
                const { status, data, currentPage, perPage, totalPage, isLoadMore } = getThemes;
                if (status) {
                    setCurrentPageData(data);
                    setCurrentPage(currentPage);
                    setPerPage(perPage);
                    setTotalPage(totalPage || 1);
                    setIsLoadMore(isLoadMore?.isLoadMore);
                } else {
                    setCurrentPageData([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getThemesData(currentPage);
        setDeleteUpdateTag("");
    }, [deleteUpdateTag, search, currentPage, perPage, filterDropdown, pagePermissions]);

    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <>
            {/* recommendedLoading={true}  */}
            <SearchAndAddBtn title={`Themes`} addBtn={pagePermissions?.CU} FilterDropdowns={<FilterDropdowns dropData={category} tabData={tab} />} deleteUpdateTag='all-delete-themes' />
            <Card className="overflow-hidden w-full rounded-md shadow-none">
                <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
                    <table className={`w-full min-w-max table-auto text-left relative`}>
                        <THead simpleFields={simpleFields} dropdownFields={dropdownFields} />
                        <tbody>
                            {
                                isLoading ?
                                    <tr>
                                        <td colSpan={totalFields} className='py-12'>
                                            <div className="flex justify-center items-center">
                                                <Spinner />
                                            </div>
                                        </td>
                                    </tr>
                                    : currentPageData?.length === 0 ?
                                        <tr>
                                            <td colSpan={totalFields} className='py-12 bg-gray-200'>
                                                <div className="flex justify-center items-center">
                                                    No Data Found
                                                </div>
                                            </td>
                                        </tr>
                                        : currentPageData?.map((_ele, i) => {
                                            const isLast = i === currentPageData?.length - 1;
                                            const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                                            return (
                                                <tr key={i} className='border-b border-blue-gray-50'>
                                                    <td className={classes}>
                                                        <Checkbox
                                                            className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                                            id={`slider-${_ele._id}`}
                                                            value={`${_ele._id}`}
                                                            disabled={!pagePermissions?.CU}
                                                            checked={checkedValues.includes(`${_ele._id}`)}
                                                            onChange={() => handleCheckboxChange(`${_ele._id}`)}
                                                        />
                                                    </td>
                                                    <td className={`${classes}`}>
                                                        <div className='imgTd relative'>
                                                            <img src={_ele.smallPreview || `../../../assets/images/no-image.png`} alt={_ele.name} className='mr-0 h-[55px] rounded-lg block absolute' />
                                                            <Zoom>
                                                                <img src={_ele.smallPreview || `../../../assets/images/no-image.png`} alt={_ele.name} className='mr-0 h-[55px] rounded-lg block' />
                                                            </Zoom>
                                                        </div>
                                                    </td>
                                                    {/* <td className={`${classes}`}>
                                                        <div className='imgTd relative'>
                                                            <img src={_ele.bigPreview || `../../../assets/images/no-image.png`} alt={_ele.name} className='mr-0 h-[55px] rounded-lg block absolute' />
                                                            <Zoom>
                                                                <img src={_ele.bigPreview || `../../../assets/images/no-image.png`} alt={_ele.name} className='mr-0 h-[55px] rounded-lg block' />
                                                            </Zoom>
                                                        </div>
                                                    </td> */}
                                                    <td className={`${classes}`}>
                                                        <div className="flex items-center">
                                                            <span className={`font-normal text-[17px] !w-28 truncate ${!_ele.name && `text-gray-500`}`}>{_ele.name || `None`}</span>
                                                            {
                                                                _ele?.name && _ele.name?.length > 12 &&
                                                                <Tooltip placement="right-start" content={
                                                                    <div className="w-auto">
                                                                        {_ele.name || `None`}
                                                                    </div>
                                                                }>
                                                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </td>
                                                    {/* <td className={classes}>
                                                        <div className="flex items-center">
                                                            <p className={`font-normal !w-40 truncate text-[17px] ${!_ele.themeCategory?.length && `text-gray-500`}`}>
                                                                {_ele.themeCategory && _ele.themeCategory?.length !== 0 && _ele.themeCategory?.length > 1 ? _ele.themeCategory.map(_ele => _ele).slice(0, -1).join(", ") + ", " + _ele.themeCategory[_ele.themeCategory.length - 1] : _ele.themeCategory && _ele.themeCategory?.length !== 0 && _ele.themeCategory[_ele.themeCategory.length - 1] || `None`}
                                                            </p>
                                                            {
                                                                _ele.themeCategory && _ele.themeCategory?.length > 1 &&
                                                                <Tooltip placement="right-start" content={
                                                                    <div className="w-60">
                                                                        {_ele.themeCategory && _ele.themeCategory?.length !== 0 && _ele.themeCategory?.length > 1 ? _ele.themeCategory.map(_ele => _ele).slice(0, -1).join(", ") + ", " + _ele.themeCategory[_ele.themeCategory.length - 1] : _ele.themeCategory && _ele.themeCategory?.length !== 0 && _ele.themeCategory[_ele.themeCategory.length - 1] || `None`}
                                                                    </div>
                                                                }>
                                                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </td> */}
                                                    <td className={classes}>
                                                        <div className="flex items-center">
                                                            <p className={`font-normal !w-40 truncate text-[17px] ${!_ele.themeTag?.length && `text-gray-500`}`}>
                                                                {_ele.themeTag && _ele.themeTag?.length !== 0 && _ele.themeTag?.length > 1 ? _ele.themeTag.map(_ele => _ele).slice(0, -1).join(", ") + ", " + _ele.themeTag[_ele.themeTag.length - 1] : _ele.themeTag && _ele.themeTag?.length !== 0 && _ele.themeTag[_ele.themeTag.length - 1] || `None`}
                                                            </p>
                                                            {
                                                                _ele.themeTag && _ele.themeTag.join("").length > 12 &&
                                                                <Tooltip placement="right-start" content={
                                                                    <div className="w-60">
                                                                        {_ele.themeTag && _ele.themeTag?.length !== 0 && _ele.themeTag?.length > 1 ? _ele.themeTag.map(_ele => _ele).slice(0, -1).join(", ") + ", " + _ele.themeTag[_ele.themeTag.length - 1] : _ele.themeTag && _ele.themeTag?.length !== 0 && _ele.themeTag[_ele.themeTag.length - 1] || `None`}
                                                                    </div>
                                                                }>
                                                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex items-center">
                                                            <p className={`font-normal !w-40 truncate text-[17px] ${!_ele.country?.length && `text-gray-500`}`}>
                                                                {_ele.country && _ele.country?.length !== 0 && _ele.country?.length > 1 ? _ele.country.map(_ele => _ele.name).slice(0, -1).join(", ") + ", " + _ele.country[_ele.country.length - 1].name : _ele.country && _ele.country?.length !== 0 && _ele.country[_ele.country.length - 1].name || `None`}
                                                            </p>
                                                            {
                                                                _ele.country && _ele.country?.length > 1 &&
                                                                <Tooltip placement="right-start" content={
                                                                    <div className="p-2 w-[300px] h-48 2xl:w-[400px] overflow-y-auto">
                                                                        {_ele.country && _ele.country?.length !== 0 && _ele.country?.length > 1 ? _ele.country.map(_ele => _ele.name).slice(0, -1).join(", ") + ", " + _ele.country[_ele.country.length - 1].name : _ele.country && _ele.country?.length !== 0 && _ele.country[_ele.country.length - 1].name || `None`}
                                                                    </div>
                                                                }>
                                                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <span className={`font-normal text-[17px] ${!_ele.userHit && `text-gray-500`}`}>{numberFormatter(_ele.userHit) || `None`}</span>
                                                    </td>
                                                    {
                                                        ["isPremium", "isTop", "isLiveToday", "isActive"]?.map((_is_ele, i) => (
                                                            <td className={classes} key={i}>
                                                                <Switch disabled={!pagePermissions?.CU} id={_ele._id} name={_is_ele} onChange={(e) => { setDeleteUpdateYes("update-themes"); handleSwitchUpdate(e); }} checked={_ele[_is_ele]} />
                                                            </td>
                                                        ))
                                                    }
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-2">
                                                            <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.CU} onClick={() => { setDeleteUpdateYes("update-themes"); setAddEditDeleteTag(`update`); setFormData(_ele); handleOpen() }}>
                                                                <BiEdit />
                                                            </IconButton>
                                                            <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.CU} onClick={() => { setAddEditDeleteTag(`view`); setFormData(_ele); handleOpen() }}>
                                                                <IoEyeOutline />
                                                            </IconButton>
                                                            <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.D} onClick={() => { setDeleteUpdateYes("delete-themes"); setDeleteUpdateItem({ id: _ele._id, name: _ele.name }); setConfirmationModalShow(!confirmationModalShow) }}>
                                                                <RiDeleteBinLine />
                                                            </IconButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                            }
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            {
                totalPage > 1 &&
                <Pagination currentPage={currentPage} totalPage={totalPage} getData={getThemesData} />
            }
            <ThemeModal />
        </>
    )
}
ThemeStores.defaultProps = {
    title: "My Photo Keyboard | Theme Stores"
};


export default ThemeStores
