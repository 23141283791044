import axios from "axios";

// export const baseURL = `https://photokeyboard.app`;
export const baseURL = process.env.REACT_APP_API_BASE_URL;
export const superAppBaseURL = process.env.REACT_APP_SUPER_API_BASE_URL;
// export const baseURL = window.location.origin;

const API = axios.create({ baseURL: `${baseURL}/v1` });
API.interceptors.request.use((req) => {
  let token = localStorage.getItem("token");
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
    localStorage.setItem("appToken", "7f8a73448952efb2811ebd40a72f090a");
  }
  return req;
});

const SUPER_API = axios.create({ baseURL: `${superAppBaseURL}` });
SUPER_API.interceptors.request.use((req) => {
  let token = localStorage.getItem("token");
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

export const adminSignin = async (payload) =>
  API.post(`/admin/signIn`, payload);
export const ldapadminSignin = async (payload) =>
  SUPER_API.post(`/admin/loginWithLdap`, payload);
export const getUserDetails = async () => API.post(`/admin/getUserDetails`);
export const getDashboard = async (_search) =>
  API.get(`/admin/dashboard?search=${_search}`);
export const getCountry = async () => API.get(`/admin/getCountry`);
export const getRegion = async () => API.get(`/admin/getRegion`);
export const getSliders = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getSlider?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getSlider`);
  }
};
export const checkUserPermissions = async (project_id) =>
  SUPER_API.post(`/admin/checkUserPermissions/${project_id}`);
export const addSliders = async (payload) =>
  API.post(`/admin/addSlider`, payload);
export const updateSliders = async (payload, id) =>
  API.patch(`/admin/updateSlider?id=${id}`, payload);
export const deleteSliders = async (id) =>
  API.delete(`/admin/deleteSlider?id=${id}`);
export const deleteAllSliders = async (payload) =>
  API.post(`/admin/deleteAllSlider`, payload);
export const pendingAllCommunityTheme = async (payload) =>
  API.post(`/admin/pendingAllCommunityTheme`, payload);
export const rejectedAllCommunityTheme = async (payload) =>
  API.post(`/admin/rejectedAllCommunityTheme`, payload);
export const approvedAllCommunityTheme = async (payload) =>
  API.post(`/admin/approvedAllCommunityTheme`, payload);

export const getSliderTag = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getSliderTag?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getSliderTag`);
  }
};
export const addSliderTag = async (payload) =>
  API.post(`/admin/addSliderTag`, payload);
export const updateSliderTag = async (payload, id) =>
  API.patch(`/admin/updateSliderTag?id=${id}`, payload);
export const deleteSliderTag = async (id) =>
  API.delete(`/admin/deleteSliderTag?id=${id}`);
export const deleteAllSliderTag = async (payload) =>
  API.post(`/admin/deleteAllSliderTag`, payload);
export const getHomeActivity = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  return API.get(`/admin/getSectionSettings`);
};
export const getHomeScreen = async () => API.post(`/admin/getHomeScreen`);
export const getWallpaperHomeScreen = async (count) =>
  axios.get(
    `https://api.unsplash.com/photos/random?collections=related&featured=true&username=&query=&w=400&h=400&orientation=portrait&count=${count}&client_id=7qITTTIejLUHTa7hTjlWEpN--uaSZmVSBtd_2bL1E_4`
  );
export const addSectionSettings = async (payload) =>
  API.post(`/admin/addSectionSettings`, payload);
export const updateSectionSettings = async (payload, id) =>
  API.patch(`/admin/updateSectionSettings?id=${id}`, payload);
export const updateSectionSettingsPriority = async (payload) =>
  API.patch(`/admin/updateSectionSettingsPriority`, payload);
export const deleteSectionSettings = async (id) =>
  API.delete(`/admin/deleteSectionSettings?id=${id}`);
export const deleteAllSectionSettings = async (payload) =>
  API.post(`/admin/deleteAllSectionSettings`, payload);
export const getNonActiveSlider = async (_currentPage, _perPage) => {
  if (_currentPage || _perPage) {
    return API.post(
      `/admin/getNonActiveSlider?page=${_currentPage}&perPage=${_perPage}`
    );
  } else {
    return API.post(`/admin/getNonActiveSlider`);
  }
};
export const getNonActiveFunctionalityFontList = async (
  _currentPage,
  _perPage
) => {
  if (_currentPage || _perPage) {
    return API.post(
      `/admin/getNonActiveFunctionalityFontList?page=${_currentPage}&perPage=${_perPage}`
    );
  } else {
    return API.post(`/admin/getNonActiveFunctionalityFontList`);
  }
};
export const getNonActiveTab = async (_currentPage, _perPage) => {
  if (_currentPage || _perPage) {
    return API.post(
      `/admin/getNonActiveTab?page=${_currentPage}&perPage=${_perPage}`
    );
  } else {
    return API.post(`/admin/getNonActiveTab`);
  }
};
export const getNonActiveGamezone = async (_currentPage, _perPage) => {
  if (_currentPage || _perPage) {
    return API.post(
      `/admin/getNonActiveGamezone?page=${_currentPage}&perPage=${_perPage}`
    );
  } else {
    return API.post(`/admin/getNonActiveGamezone`);
  }
};
export const getNonCustomButtonHomeScreen = async (_currentPage, _perPage) => {
  if (_currentPage || _perPage) {
    return API.post(
      `/admin/getNonCustomButtonHomeScreen?page=${_currentPage}&perPage=${_perPage}`
    );
  } else {
    return API.post(`/admin/getNonCustomButtonHomeScreen`);
  }
};
export const getHomeScreenKey = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => API.post(`/admin/getHomeScreenKey`);
export const addHomeScreenKey = async (payload) =>
  API.post(`/admin/addHomeScreenKey`, payload);
export const updateHomeScreenKey = async (payload, id) =>
  API.patch(`/admin/updateHomeScreenKey?id=${id}`, payload);
export const deleteHomeScreenKey = async (id) =>
  API.delete(`/admin/deleteHomeScreenKey?id=${id}`);
export const deleteAllHomeScreenKey = async (payload) =>
  API.post(`/admin/deleteAllHomeScreenKey`, payload);
export const getCustomeButtonHomeScreen = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getCustomButtonHomeScreen?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getCustomButtonHomeScreen`);
  }
};
export const getStore = async (_currentPage, _perPage, _search, _sortName, _sortType, _filterDropdown) => {
  let urlString = `/admin/store/getStore?`;
  if (_currentPage) urlString += `page=${_currentPage}`;
  if (_perPage) urlString += `&perPage=${_perPage}`;
  if (_search) urlString += `&search=${_search}`;
  if (_sortName) urlString += `&sortName=${_sortName}`;
  if (_sortType) urlString += `&sortType=${_sortType}`;
  return API.post(urlString, _filterDropdown);
};

export const updateStore = async (payload, id) =>
  API.patch(`/admin/store/updateStore?id=${id}`, payload);
export const addStore = async (payload) =>
  API.post(`/admin/store/addStore`, payload);
export const deleteStore = async (id) =>
  API.delete(`/admin/store/deleteStore?id=${id}`);
export const deleteAllStore = async (payload) =>
  API.post(`/admin/store/deleteAllStore`, payload);

export const addCustomeButtonHomeScreen = async (payload) =>
  API.post(`/admin/addCustomButtonHomeScreen`, payload);
export const updateCustomeButtonHomeScreen = async (payload, id) =>
  API.patch(`/admin/updateCustomButtonHomeScreen?id=${id}`, payload);
export const deleteCustomeButtonHomeScreen = async (id) =>
  API.delete(`/admin/deleteCustomButtonHomeScreen?id=${id}`);
export const deleteAllCustomeButtonHomeScreen = async (payload) =>
  API.post(`/admin/deleteAllCustomButtonHomeScreen`, payload);
export const getHomeScreenBanners = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getHomescreenBanner?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getHomescreenBanner`);
  }
};
export const addHomescreenBanner = async (payload) =>
  API.post(`/admin/addHomescreenBanner`, payload);
export const updateHomescreenBanner = async (payload, id) =>
  API.patch(`/admin/updateHomescreenBanner?id=${id}`, payload);
export const deleteHomescreenBanner = async (id) =>
  API.delete(`/admin/deleteHomescreenBanner?id=${id}`);
export const deleteAllHomescreenBanner = async (payload) =>
  API.post(`/admin/deleteAllHomescreenBanner`, payload);
export const getFunctionalityFontList = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getFunctionalityFontList?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getFunctionalityFontList`);
  }
};
export const addFunctionalityFontList = async (payload) =>
  API.post(`/admin/addFunctionalityFontList`, payload);
export const updateFunctionalityFontList = async (payload, id) =>
  API.patch(`/admin/updateFunctionalityFontList?id=${id}`, payload);
export const deleteFunctionalityFontList = async (id) =>
  API.delete(`/admin/deleteFunctionalityFontList?id=${id}`);
export const deleteAllFunctionalityFontList = async (payload) =>
  API.post(`/admin/deleteAllFunctionalityFontList`, payload);
export const getThemes = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getTheme?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getTheme`);
  }
};
export const addTheme = async (payload) => API.post(`/admin/addTheme`, payload);
export const updateTheme = async (payload, id) =>
  API.patch(`/admin/updateTheme?id=${id}`, payload);
export const deleteTheme = async (id) =>
  API.delete(`/admin/deleteTheme?id=${id}`);
export const deleteAllThemes = async (payload) =>
  API.post(`/admin/deleteAllTheme`, payload);
export const getCommunityThemeAPI = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getCommunityTheme?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getCommunityTheme`);
  }
};
// ===================================================================================
export const getCommunityPendingThemeAPI = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getCommunityPendingTheme?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getCommunityPendingTheme`);
  }
};
// ===================================================================================
export const deleteApprovedTheme = async (id) =>
  API.delete(`/admin/deleteCommunityTheme?id=${id}`);
export const deleteAllApprovedTheme = async (payload) =>
  API.post(`/admin/deleteAllCommunityTheme`, payload);
export const updateApprovedTheme = async (payload, id) =>
  API.patch(`/admin/updateCommunityTheme?id=${id}`, payload);
export const getCommunityUserReportAPI = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getCommunityOffensiveReport?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getCommunityOffensiveReport`);
  }
};
export const getCommunityUser = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getCommunityUser?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getCommunityUser`);
  }
};
export const getCommunityReason = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getCommunityReason?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getCommunityReason`);
  }
};
export const addCommunity_Reason = async (payload) =>
  API.post(`/admin/addCommunityReason`, payload);
export const updateCommunity_Reason = async (payload, id) =>
  API.patch(`/admin/updateCommunityReason?id=${id}`, payload);
export const deleteCommunityReason = async (id) =>
  API.delete(`/admin/deleteCommunityReason?id=${id}`);
export const deleteAllCommunityReason = async (payload) =>
  API.post(`/admin/deleteAllCommunityReason`, payload);

export const getOneThemeOffensiveReport = async (id) =>
  API.get(`/admin/getOneThemeOffensiveReport?themeId=${id}`);
export const deleteCommunityUser = async (id) =>
  API.delete(`/admin/deleteCommunityUser?id=${id}`);
export const deleteAllCommunityUser = async (payload) =>
  API.post(`/admin/deleteAllCommunityUser`, payload);

export const deleteCommunityReport = async (id) =>
  API.delete(`/admin/deleteCommunityReport?id=${id}`);
export const deleteAllCommunityReport = async (payload) =>
  API.post(`/admin/deleteAllCommunityReport`, payload);
export const getThemeCategory = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getCategory?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getCategory`);
  }
};
export const addThemeCategory = async (payload) =>
  API.post(`/admin/addCategory`, payload);
export const updateThemeCategory = async (payload, id) =>
  API.patch(`/admin/updateCategory?id=${id}`, payload);
export const deleteThemeCategory = async (id) =>
  API.delete(`/admin/deleteCategory?id=${id}`);
export const deleteAllCategory = async (payload) =>
  API.post(`/admin/deleteAllCategory`, payload);
export const updateCategoryPriority = async (payload) =>
  API.patch(`/admin/updateCategoryPriority`, payload);

export const getTag = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getTag?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getTag`);
  }
};
export const addTag = async (payload) => API.post(`/admin/addTag`, payload);
export const updateTag = async (payload, id) =>
  API.patch(`/admin/updateTag?id=${id}`, payload);
export const deleteUpdateTag = async (id) =>
  API.delete(`/admin/deleteUpdateTag?id=${id}`);
export const deleteAllTag = async (payload) =>
  API.post(`/admin/deleteAllTag`, payload);
export const getTab = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getTab?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getTab`);
  }
};
export const addTab = async (payload) => API.post(`/admin/addTab`, payload);
export const updateTab = async (payload, id) =>
  API.patch(`/admin/updateTab?id=${id}`, payload);
export const deleteUpdateTab = async (id) =>
  API.delete(`/admin/deleteUpdateTab?id=${id}`);
export const deleteAllTab = async (payload) =>
  API.post(`/admin/deleteAllTab`, payload);
export const getBackground = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getBackgroundlist?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getBackgroundlist`);
  }
};
export const addBackground = async (payload) =>
  API.post(`/admin/addBackgroundlist`, payload);
export const updateBackground = async (payload, id) =>
  API.patch(`/admin/updateBackgroundlist?id=${id}`, payload);
export const deleteBackground = async (id) =>
  API.delete(`/admin/deleteBackgroundlist?id=${id}`);
export const deleteAllBackground = async (payload) =>
  API.post(`/admin/deleteAllBackgroundlist`, payload);
export const getBackgroundCategory = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getBackgroundCategory?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getBackgroundCategory`);
  }
};
export const addBackgroundCategory = async (payload) =>
  API.post(`/admin/addBackgroundCategory`, payload);
export const updateBackgroundCategory = async (payload, id) =>
  API.patch(`/admin/updateBackgroundCategory?id=${id}`, payload);
export const deleteBackgroundCategory = async (id) =>
  API.delete(`/admin/deleteBackgroundCategory?id=${id}`);
export const deleteAllBackgroundCategory = async (payload) =>
  API.post(`/admin/deleteAllBackgroundCategory`, payload);
export const getEffects = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getEffect?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getEffect`);
  }
};
export const addEffect = async (payload) =>
  API.post(`/admin/addEffect`, payload);
export const updateEffect = async (payload, id) =>
  API.patch(`/admin/updateEffect?id=${id}`, payload);
export const deleteEffect = async (id) =>
  API.delete(`/admin/deleteEffect?id=${id}`);
export const deleteAllEffect = async (payload) =>
  API.post(`/admin/deleteAllEffect`, payload);
export const getFontList = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getFontlist?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getFontlist`);
  }
};
export const addFontList = async (payload) =>
  API.post(`/admin/addFontlist`, payload);
export const updateFontList = async (payload, id) =>
  API.patch(`/admin/updateFontlist?id=${id}`, payload);
export const deleteFontLists = async (id) =>
  API.delete(`/admin/deleteFontlist?id=${id}`);
export const deleteAllFontlist = async (payload) =>
  API.post(`/admin/deleteAllFontlist`, payload);
export const getSounds = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getSoundlist?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getSoundlist`);
  }
};
export const addSounds = async (payload) =>
  API.post(`/admin/addSoundlist`, payload);
export const updateSounds = async (payload, id) =>
  API.patch(`/admin/updateSoundlist?id=${id}`, payload);
export const deleteSounds = async (id) =>
  API.delete(`/admin/deleteSoundlist?id=${id}`);
export const deleteAllSoundlist = async (payload) =>
  API.post(`/admin/deleteAllSoundlist`, payload);
export const getKey = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getKeyList?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getKeyList`);
  }
};
export const addKey = async (payload) => API.post(`/admin/addKeyList`, payload);
export const updateKey = async (payload, id) =>
  API.patch(`/admin/updateKeyList?id=${id}`, payload);
export const deleteKey = async (id) =>
  API.delete(`/admin/deleteKeyList?id=${id}`);
export const deleteAllKey = async (payload) =>
  API.post(`/admin/deleteAllKeyList`, payload);
export const getTextArt = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getTextArt?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getTextArt`);
  }
};
export const addTextArt = async (payload) =>
  API.post(`/admin/addTextArt`, payload);
export const updateTextArt = async (payload, id) =>
  API.patch(`/admin/updateTextArt?id=${id}`, payload);
export const deleteTextArt = async (id) =>
  API.delete(`/admin/deleteTextArt?id=${id}`);
export const deleteAllTextArt = async (payload) =>
  API.post(`/admin/deleteAllTextArt`, payload);
export const getTextArtCategory = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getTextArtCategory?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getTextArtCategory`);
  }
};
export const addTextArtCategory = async (payload) =>
  API.post(`/admin/addTextArtCategory`, payload);
export const updateTextArtCategory = async (payload, id) =>
  API.patch(`/admin/updateTextArtCategory?id=${id}`, payload);
export const deleteTextArtCategory = async (id) =>
  API.delete(`/admin/deleteTextArtCategory?id=${id}`);
export const deleteAllTextArtCategory = async (payload) =>
  API.post(`/admin/deleteTextArtCategory`, payload);
export const getKamoji = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getKamoji?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getKamoji`);
  }
};
export const addKamoji = async (payload) =>
  API.post(`/admin/addKamoji`, payload);
export const updateKamoji = async (payload, id) =>
  API.patch(`/admin/updateKamoji?id=${id}`, payload);
export const deleteKamoji = async (id) =>
  API.delete(`/admin/deleteKamoji?id=${id}`);
export const deleteAllKamoji = async (payload) =>
  API.post(`/admin/deleteAllKamoji`, payload);
export const getFancyFont = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getFancyFontList?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getFancyFontList`);
  }
};
export const addFancyFont = async (payload) =>
  API.post(`/admin/addFancyFontList`, payload);
export const updateFancyFont = async (payload, id) =>
  API.patch(`/admin/updateFancyFontList?id=${id}`, payload);
export const deleteFancyFont = async (id) =>
  API.delete(`/admin/deleteFancyFontList?id=${id}`);
export const deleteAllFancyFont = async (payload) =>
  API.post(`/admin/deleteAllFancyFontList`, payload);
export const getStickerStore = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getStickerStore?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getStickerStore`);
  }
};
export const addStickerStore = async (payload) =>
  API.post(`/admin/addStickerStore`, payload);
export const updateStickerStore = async (payload, id) =>
  API.patch(`/admin/updateStickerStore?id=${id}`, payload);
export const deleteStickerStore = async (id) =>
  API.delete(`/admin/deleteStickerStore?id=${id}`);
export const deleteAllStickerStore = async (payload) =>
  API.post(`/admin/deleteAllStickerStore`, payload);
export const addSetting = async (payload) =>
  API.post(`/admin/addSetting`, payload);
export const getWallpaper = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.get(
      `/admin/getWallpaper?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.get(`/admin/getWallpaper`);
  }
};
export const addWallpaper = async (payload) =>
  API.post(`/admin/addWallpaper`, payload);
export const updateWallpaper = async (payload, id) =>
  API.patch(`/admin/updateWallpaper?id=${id}`, payload);
export const deleteWallpaper = async (id) =>
  API.delete(`/admin/deleteWallpaper?id=${id}`);
export const deleteAllWallpaper = async (payload) =>
  API.post(`/admin/deleteAllWallpaperlist`, payload);
export const getWallpaperCategory = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getWallpaperCategory?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getWallpaperCategory`);
  }
};
export const addWallpaperCategory = async (payload) =>
  API.post(`/admin/addWallpaperCategory`, payload);
export const updateWallpaperCategory = async (payload, id) =>
  API.patch(`/admin/updateWallpaperCategory?id=${id}`, payload);
export const deleteWallpaperCategory = async (id) =>
  API.delete(`/admin/deleteWallpaperCategory?id=${id}`);
export const deleteAllWallpaperCategory = async (payload) =>
  API.post(`/admin/deleteAllWallpaperCategory`, payload);
export const getHistory = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/history?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/history`);
  }
};
export const deleteAllHistory = async (payload) =>
  API.post(`/admin/deleteHistory`, payload);
export const getHistoryFilterList = async () =>
  API.post(`/admin/getHistoryFilterList`);
export const userDetails = async () => API.post(`/admin/userDetails`);
export const getStorage = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    return API.post(
      `/admin/getStorage?page=${_currentPage}&perPage=${_perPage}&search=${_search}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getStorage`);
  }
};
export const getCommunityStorageChart = async (_filterDropdown) => {
  if (_filterDropdown) {
    return API.post(`/admin/getStorageChart`, _filterDropdown);
  } else {
    return API.post(`/admin/getStorageChart`);
  }
};
export const deleteStorage = async (id) =>
  API.delete(`/admin/deleteStorage?id=${id}`);
export const deleteAllStorage = async (payload) =>
  API.post(`/admin/deleteAllStorage`, payload);
export const removeAllCommunityExtraFiles = async () =>
  API.post(`/admin/removeAllCommunityExtraFiles`);
export const getCommonStorage = async () => API.post(`/admin/getCommonStorage`);
export const removeCommonStorage = async (payload) =>
  API.post(`/admin/removeCommonStorage`, payload);

//Blogs categories
export const getBlogCategories = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    const searchData = _search.trim();
    return API.post(
      `/admin/getCategories?page=${_currentPage}&perPage=${_perPage}&search=${searchData}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getCategories`);
  }
};
export const addBlogCategories = async (payload) =>
  API.post(`/admin/addCategories`, payload);
export const updateBlogCategories = async (payload, id) =>
  API.patch(`/admin/updateCategories?id=${id}`, payload);
export const deleteBlogCategories = async (id) =>
  API.delete(`/admin/deleteCategories?id=${id}`);
export const deleteAllBlogCategories = async (payload) =>
  API.post(`/admin/deleteAllCategories`, payload);

//Blogs
export const getBlog = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    const searchData = _search.trim();
    return API.post(
      `/admin/getBlogs?page=${_currentPage}&perPage=${_perPage}&search=${searchData}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getBlogs`);
  }
};
export const getOneBlog = async (id) =>
  API.get(`/admin/getOneBlog?slug=${id}`);
export const addBlog = async (payload) => API.post(`/admin/addBlogs`, payload);
export const updateBlog = async (payload, id) =>
  API.patch(`/admin/updateBlogs?id=${id}`, payload);
export const deleteBlog = async (id) =>
  API.delete(`/admin/deleteBlogs?id=${id}`);
export const deleteAllBlog = async (payload) =>
  API.post(`/admin/deleteAllBlogs`, payload);

//FAQ
export const getFAQ = async (_filterDropdown) => {
  if (_filterDropdown) {
    return API.post(`/admin/getFaq`, _filterDropdown);
  } else {
    return API.post(`/admin/getFaq`);
  }
};
export const getOneFAQ = async (id) => {
  return API.get(`/admin/getOneFAQ?id=${id}`);
};
export const addFAQ = async (payload) => API.post(`/admin/addFaq`, payload);
export const updateFAQ = async (payload, id) =>
  API.patch(`/admin/updateFaq?id=${id}`, payload);
export const deleteFAQ = async (id) => API.delete(`/admin/deleteFaq?id=${id}`);

export const deleteMediaFAQ = async (payload) =>
  API.post(`/admin/deleteMediaFaq`, payload);

export const deleteAllFAQ = async (payload) =>
  API.delete(`/admin/deleteAllFaq`, payload);

export const updateFaqPriority = async (payload) =>
  API.post(`/admin/updateFaqPriority`, payload);
export const updateFaqMediaPriority = async (payload) =>
  API.post(`/admin/updateFaqMediaPriority`, payload);

// survey
export const addSurvey = async (payload) => API.post(`/admin/addSurvey`, payload);
export const getSurvey = async (_filterDropdown) => {
  if (_filterDropdown) {
    return API.post(`/admin/getSurvey`, _filterDropdown);
  } else {
    return API.post(`/admin/getSurvey`);
  }
};
export const deleteSurvey = async (id) => API.delete(`/admin/deleteSurvey?id=${id}`);
export const updateSurvey = async (payload, id) =>
  API.patch(`/admin/updateSurvey?id=${id}`, payload);

export const surveyAnalysis = async (_filterDropdown) => {
  if (_filterDropdown) {
    return API.post(`/admin/surveyAnalysis`, _filterDropdown);
  } else {
    return API.post(`/admin/surveyAnalysis`);
  }
};

// asset
export const addAsset = async (payload) => API.post(`/admin/addAsset`, payload);

export const getAsset = async (_currentPage,
  _perPage) => {
  if (_currentPage || _perPage) {
    return API.post(
      `/admin/getAsset?page=${_currentPage}&perPage=${_perPage}`
    );
  } else {
    return API.post(`/admin/getAsset`);
  }

};

export const getAssetLink = async () => {
  return API.post(`/admin/getAssetLink`);
};

export const updateAsset = async (payload, id) =>
  API.post(`/admin/updateAsset?id=${id}`, payload);

export const deleteAsset = async (id) => API.delete(`/admin/deleteAsset?id=${id}`);

//Blogs Comments
export const getBlogComments = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  if (_currentPage || _perPage || _search || _filterDropdown) {
    const searchData = _search.trim();
    return API.post(
      `/admin/getReply?page=${_currentPage}&perPage=${_perPage}&search=${searchData}`,
      _filterDropdown
    );
  } else {
    return API.post(`/admin/getReply`);
  }
};
export const deleteReply = async (id) =>
  API.delete(`/admin/deleteReply?id=${id}`);
export const deleteAllReply = async (payload) =>
  API.post(`/admin/deleteAllReply`, payload);
export const zipValidation = async (payload) =>
  API.post(`/admin/zipFileValidation`, payload);
